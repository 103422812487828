export default {
  primary: {
    border: 'none',
    fontSize: '1.2rem',
    lineHeight: 1,
    background:
      'linear-gradient(90deg,#77bfdc calc(100% - 24px),transparent calc(100% - 24px)) 12px 0 repeat-x,linear-gradient(90deg,#77bfdc calc(100% - 24px),transparent calc(100% - 24px)) 12px 100% repeat-x,linear-gradient(180deg,#77bfdc 0,#77bfdc calc(100% - 7px),transparent calc(100% - 7px)) 0 0 repeat-y,linear-gradient(0deg,#77bfdc 0,#77bfdc calc(100% - 7px),transparent calc(100% - 7px),transparent) 100% 100% repeat-y',
    backgroundSize: '100% 4px,100% 4px,4px 100%,4px 100%',
    marginLeft: '0rem',
    marginRight: '0rem',
    padding: ['1rem 2rem', '2rem 3rem'],
    textTransform: 'uppercase',
    color: 'primary',
    fontFamily: 'display',
    WebkitTransition: 'all .2s cubic-bezier(.42,0,.16,1)',
    transition: 'all .2s cubic-bezier(.42,0,.16,1)',
    textAlign: 'center',
    ':hover': {
      backgroundPosition: '0 0,0 100%,0 0,100% 100%',
      backgroundSize:
        'calc(100% + 48px - 4px) 4px,calc(100% + 48px - 4px) 4px,4px calc(100% + 7px),4px calc(100% + 7px)',
      padding: '2rem calc(3rem - 4px);'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    padding: '1rem 0.5rem',
    color: 'primary',
    border: 'none',
    borderTop: 'solid 1px',
    borderBottom: 'solid 1px',
    borderColor: 'primary',
    borderRadius: '0px',
    fontWeight: 'bold',
    display: 'flex',
    fontSize: '0.85rem',
    ':hover': {
      opacity: '0.4',
      backgroundColor: 'transparent',
      color: 'grey'
    }
  }
}
