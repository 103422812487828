export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    // ? == container ==
    '.container': {
      backgroundColor: 'background',
      // borderBottom: "2px solid",
      // borderColor: "secondary",
      padding: ['0.5rem', '0.5rem', '1rem', '1rem'],
      zIndex: '1001'
    },
    '.containerScrolled': {
      backgroundColor: 'white',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      zIndex: '1001',
      '.hamburger > div': {
        backgroundColor: 'primary'
      }
    },

    // ? == Logo ==

    '.navLogo': {
      position: 'static',
      padding: '0rem',
      a: {
        height: '100%'
      },
      img: {
        opacity: '1',
        maxHeight: ['40px', '40px']
      }
    },
    '.navLogoScrolled ': {
      position: 'static',
      backgroundColor: 'transparent',
      padding: '0rem'
    },
    '.hamburger': {
      order: '3',
      div: {
        backgroundColor: 'dark'
      }
    },
    '.hamburgerOpen': {
      order: '3'
    },

    // ? == nav Menus ==

    '.smallNavMenu > div': {
      border: 'none',
      '.navItem': {
        color: 'secondary',
        a: {
          fontSize: ['1rem', '1rem', '1rem', '1rem']
        }
      }
    },

    '.navItem': {
      color: 'light',
      margin: '0rem',
      padding: ['0.75rem', '0.75rem', '1rem'],
      width: '100%',
      flexGrow: '1',
      ':hover': {
        color: 'inherit'
      }
    },

    '.menuLogo ': {
      position: ['', '', 'absolute'],
      left: '50%',
      top: '5%',
      width: ['100%', '', 'fit-content'],
      margin: ['0rem 0rem 1rem', '', '0rem'],
      transform: ['', '', 'translateX(-50%)'],
      textAlign: 'center',
      img: {
        maxHeight: ['55px', '', '200px', '']
      }
    },
    '.navMenuOpen': {
      backgroundColor: 'background',
      width: ['100%', '', '100%'],
      justifyContent: ['center', 'center', 'center'],
      padding: ['2rem', '', '0rem'],
      '.seperatorLine': {
        width: '100%',
        maxWidth: 'unset',
        borderColor: 'secondary',
        margin: '1rem 0rem 0rem'
      },
      '.navItem': {
        padding: ['0.75rem', '0.75rem', '1rem', '0.75rem', '0.75rem', '1rem'],
        a: {
          fontSize: ['1rem', '1.25rem', '1.5rem', '1.75rem']
        }
      }
    },
    '.navBlockOpen': {
      position: 'fixed',
      left: '0rem',
      top: '0rem',
      width: '100%',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(30 24 6 / 70%)'
    },

    // ? == widgets ==

    '.phoneSocialContainer': {
      width: '100%',
      maxWidth: 'unset',
      backgroundColor: '#4d442d',
      paddingBottom: '1rem'
    },
    '.socialIconsContainer, .phoneContainer': {
      '.smallNavHeading': {
        fontSize: ['1.1rem', '1.5rem']
      },
      margin: '0rem',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'light',
      border: 'none',
      width: '100%',
      fontSize: '1rem',
      padding: ['1rem 0rem', '1rem 0rem', '1rem 0rem'],
      maxWidth: 'unset',
      svg: {
        path: {
          fill: 'light'
        }
      },
      '.socialContainer': {
        justifyContent: 'flex-start',
        padding: '1rem 0rem'
      }
    }
  },

  footer: {
    backgroundColor: 'black',
    display: 'flex',
    flexWrap: 'wrap',
    color: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.5rem',
    paddingBottom: ['3rem', '', '0.5rem'],
    marginTop: ['', '', ''],
    '.gonationLogo': {
      filter: 'brightness(0) invert(1)',
      maxHeight: '0.9rem',
      margin: ['0.25rem', '0.25rem 0.5rem'],
      textAlign: 'center',
      svg: {
        height: '1rem'
      },
      span: {
        margin: '0rem auto'
      }
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'white',
      margin: ['0.25rem', '0.25rem 0.5rem'],
      fontSize: '0.8rem'
    },
    '.multiButtonContainer, .image, .socialContainer  ': {
      display: 'none'
    },

    '.contactDetails-container': {
      display: 'flex',
      color: 'white',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'center',
      '.text': {
        // margin: '0rem 1rem',
        margin: ['0.25rem', '0.25rem 0.5rem'],
        fontSize: '0.8rem',
        display: 'flex',
        color: 'white',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',

        a: {
          display: 'flex',
          flexWrap: 'no-wrap',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }
    }
  },

  ctaWidget: {
    a: {
      backgroundColor: 'primary',
      span: {
        display: 'block'
      }
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    borderColor: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['2rem', '2.25rem', '2.25rem', '2.5rem'],
    fontFamily: 'display',
    color: 'primary',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem',
    textTransform: 'uppercase'
  },

  subtitle: {
    color: 'secondary',
    fontWeight: 'bold',
    textTransform: 'initial',
    fontSize: '0.85rem',
    marginBottom: '1.5rem',
    textTransform: 'uppercase'
  },

  text: {},

  centeredSection: {
    '.section': {
      '::after': {
        content: "''",
        position: 'absolute',
        width: '100%'
      },
      maxWidth: 'unset',

      width: '100%',
      margin: '0rem auto',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      width: '100%',
      '.title': {
        variant: 'customVariants.title',
        color: 'white',
        border: 'none'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        color: 'white'
      },
      '.text': {
        maxWidth: '1000px',
        margin: '0rem',
        p: {
          variant: 'customVariants.text',
          color: 'white',
          fontSize: ['0.9', '', '1.1rem'],
          lineHeight: ['1.25', '1.5', '1.75']
        }
      },
      a: {},
      '.ctaButton': {
        variant: 'buttons.primary',
        filter: 'brightness(0) invert(1)'
      }
    }
  },

  sideBySide1: {
    padding: ['0rem', '1rem', '2rem 2rem 0rem', '3rem 3rem 0rem'],
    height: ['', '', '75vh', '75vh'],
    '.content': {
      // backgroundColor: '#ded5ca',
      width: ['', '', '45%'],
      padding: ['1rem', '', '2rem', '1.5rem 3.5rem', '2.5rem 4.5rem'],

      a: {
        order: '4'
      }
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'dark'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    '.lazyload-wrapper': {
      width: ['', '', '55%'],
      img: {
        // height: '80vh',
        display: 'flex'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.content': {
      // backgroundColor: '#ded5ca',
      width: ['', '', '45%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      order: ['', '', '2']
    }
  },
  // sideBySide3: {
  //   variant: 'customVariants.sideBySide1',
  //   '.content': {
  //     backgroundColor: '#c4d4b4',
  //     width: ['', '', '35%'],
  //     padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
  //   },
  // },
  // sideBySide4: {
  //   variant: 'customVariants.sideBySide1',
  //   '.content': {
  //     backgroundColor: '#ded5ca',
  //     width: ['', '', '35%'],
  //     padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
  //     order: '2',
  //   },
  // },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    height: '100vh',
    width: '100%',
    position: 'relative',
    '.logoHero': {
      filter: 'brightness(0) invert(1)',
      maxHeight: '125px'
    },
    '.hero_content_container': {
      justifyContent: 'center',
      alignItems: 'center',
      width: '90%',
      // height: "100%",
      // width: "100%",
      flexDirection: 'column',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      zIndex: '10'
    },
    '.title': {
      variant: 'customVariants.title',
      // borderTop: '2px solid',
      textTransform: 'unset',
      color: 'primary',
      textAlign: 'center',
      fontFamily: 'display2'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      marginTop: '2rem',
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      filter: 'brightness(0) invert(1)' // !makes the button all white color
    },
    '::after': {
      content: "''",
      width: '40px',
      height: '40px',
      borderBottom: '3px solid',
      borderRight: '3px solid',
      position: 'absolute',
      bottom: '2rem',
      left: '50%',
      transform: 'translateX(-50%) rotate(45deg)'
    },

    '.slick-slider, .slick-list, .slick-slide, .slick-slide img': {
      height: '100vh',
      width: '100%'
    },

    '.slick-slide img': {
      objectFit: 'cover'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    // maxHeight: ['', '', '50vh'],
    padding: ['1rem', '', '3rem'],
    height: ['', '', '75vh', '75vh'],
    '.imageContainer': {
      order: ['3', '', '3']
    },
    '.content': {
      padding: ['1rem', '', '', '4rem', '5rem'],
      alignItems: 'flex-start',
      textAlign: 'left'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'text',
      textAlign: 'left',
      margin: '0rem 0rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      margin: '1rem 0rem'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      maxWidth: 'unset'
    },
    '.date': {
      textAlign: 'left',
      margin: '1rem 0rem',
      order: '2'
    },
    '.shoutCTABtns': {
      order: '5',
      '.shoutCTA': {
        variant: 'buttons.primary',
        padding: '1rem',
        fontSize: '1rem'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide2'
  },
  homepageMenu: {
    variant: 'customVariants.sideBySide1'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide2'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide1'
  },

  homepageHours: {
    variant: 'customVariants.sideBySide2',
    height: ['auto', 'auto', 'auto', 'auto', 'auto'],
    maxHeight: 'unset',
    margin: '2rem 0rem'
  },

  homepageReviews: {
    padding: ['2rem 1rem', '', '4rem 1rem'],
    maxWidth: 'unset',

    '.menuSectionTitle': {
      variant: 'customVariants.title',
      border: 'none',
      textAlign: 'center',
      margin: '0 auto 1rem'
    },

    '.menuSectionDescription': {
      variant: 'customVariants.text',
      fontSize: '1rem'
    },

    '.menuItemNameContainer': {
      variant: 'customVariants.subtitle',
      order: '4',
      margin: '1rem auto 0rem',
      textAlign: 'center'
    },

    '.menuItemName': {
      fontSize: ['1rem', '1rem', '1rem', '1rem']
    },

    '.menuItemDescription': {
      variant: 'customVariants.text',
      color: 'primary',
      fontSize: '1rem',
      textAlign: 'center',
      lineHeight: '1.5',
      width: '100%',
      padding: ['0rem', '', '0rem 1rem'],
      margin: '0 auto 1rem'
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto'
    }
  },

  homepageBoxes: {
    padding: '0rem',
    'div:nth-of-type(6)': {
      backgroundColor: '#c5c1af'
    },
    '.box': {
      padding: '0rem',
      width: ['100%', '', '50%', '33.3%'],
      position: 'relative',
      height: '450px',
      background: 'transparent',
      color: 'white',
      margin: '0rem',
      borderRadius: '0px'
    },
    '.image': {
      position: 'absolute',
      height: '450px',
      zIndex: '-1',
      objectFit: 'cover',
      filter: 'brightness(0.55)'
    },
    '.title': {
      padding: '0rem 2rem',
      order: '2',
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '1.75rem', '2rem', '', '2.25rem']
    },
    '.subtitle': {
      margin: '3rem 2rem 1rem',
      padding: '1rem 0rem',
      borderBottom: '2px solid white',
      width: 'calc(100% - 4rem)',
      textTransform: 'uppercase',
      order: '1',
      fontWeight: '400'
    },
    '.text': {
      padding: '0rem 2rem',
      order: '3',
      color: 'white',
      margin: '1rem 0rem 2rem'
    },
    '.ctaLink': {
      variant: 'buttons.secondary',
      margin: '0rem 2rem 3rem',
      order: '4',
      borderColor: 'white',
      color: 'white',
      width: 'fit-content',
      textTransform: 'uppercase'
    }
  },
  homepageOrder: {
    marginTop: ['2rem', '', ''],
    backgroundColor: '#fbfbfb',
    '.title': {
      marginBottom: '0rem'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    '.title': {
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  aboutpageTeam: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      width: ['', '', '55%'],
      '::before': {
        background: 'unset'
      },
      img: {
        height: ''
      }
    }
  },

  aboutpageHistory: {
    variant: 'customVariants.sideBySide2'
    // '.lazyload-wrapper': {
    //   order: '2',
    //   width: ['', '', '55%'],
    //   '::before': {
    //     background: 'unset',
    //   },
    //   img: {
    //     height: '',
    //   },
    // },
  },
  aboutpageCareer: {
    variant: 'customVariants.sideBySide1'
    // '.lazyload-wrapper': {
    //   width: ['', '', '55%'],
    //   '::before': {
    //     background: 'unset',
    //   },
    //   img: {
    //     height: '',
    //   },
    // },
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    // border: 'solid 1px black',
    padding: ['1rem', '1rem'],
    margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
    width: ['calc(100% - 1rem)'],
    backgroundColor: 'white',
    maxWidth: 'unset',
    '.backToMenuBtn': {
      variant: 'buttons.primary'
    },
    '.section-cell': {
      width: ['100%', '', 'calc(50% - 1rem)'],
      borderRadius: '0px',
      background: 'transparent'
    },

    '.menuCell': {
      variant: 'buttons.primary',
      padding: '1rem'
    },
    '.cellImageContainer': {
      '.imageFill': {
        paddingBottom: '70%'
      }
    },

    '.cellImage ': {
      borderRadius: '0px'
    },
    '.dropdownContainer': {
      display: 'none'
    },

    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.tabsRow': {
      padding: '0rem 0rem 1rem',
      marginBottom: '2rem'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },

    '.menuItemsContainer': {
      padding: ['', '', '', '1rem 3rem', '1rem 5rem']
    },

    '.menuItemInnerContainer, .menuItemContentContainer': {
      margin: '0rem'
    },
    '.menuSectionTitle': {
      variant: 'buttons.primary',
      textAlign: 'center',
      fontSize: ['1.5rem', '', '2rem'],
      ':hover': {} // ! reset hover to nothing
    },

    '.menuItemName': {
      color: 'rgb(132, 112, 112)',
      textTransform: 'uppercase',
      fontWeight: '600',
      margin: '0rem',
      fontSize: '1.25rem'
    },

    '.menuSectionDescription': {
      fontSize: '1.1rem',
      textAlign: 'center',
      margin: ['1rem', '', '1rem 1.5rem']
    },
    '.menuItemDescription': {
      color: 'primary'
    },
    '.menuPriceContainer': {
      fontFamily: 'heading'
      // display: 'none',
    },
    '.variantsContainer': {
      // display: 'none',
    },
    '.menuItemPrice': {
      color: 'rgb(132, 112, 112)',
      fontWeight: '600'
    },
    '.menuItemImageContainer': {
      marginRight: '0.5rem'
    },
    '.menuItemImg': {
      variant: 'buttons.primary',
      width: '150px',
      padding: ['0.5rem', '0.5rem', '0.5rem', '0.5rem'],
      ':hover': {} // !resets the hover to do nothing
    },
    '.menuItemPriceVariants': {
      margin: '0rem 0rem 0rem auto'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      img: {
        filter: 'brightness(1) invert(1)'
      }
    },
    '.eventItemImage': {
      minHeight: 'unset',
      maxHeight: 'unset',
      height: '100%'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    order: '4',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: 'rgb(222, 213, 202)',
    color: 'black',
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderBottom: '2px solid',
      borderColor: 'black',
      fontWeight: '500'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {},

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {}
}
