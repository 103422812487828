export default {
  heading: 'Open Sans, serif',
  body: 'Open Sans, sans-serif',
  monospace: 'Menlo, monospace',
  // display: "Yatra One, serif",
  display: 'Open Sans, sans-serif',
  display2: 'Julius Sans One',

  googleFonts: ['Open Sans:100,200,300,400,500,600,700,800,900', 'Yatra One', 'Julius Sans One']
  // customFamilies: [''],
  // customUrls: [''],
}
